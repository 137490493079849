import React from "react";

import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/SEO/seo";
import AboutUs from "../../../components/About";

const AboutPage = () => (
	<Layout>
		<SEO
			title="Taxidienste Hamburg | Taxidienste Kiel und Neumünster"
			meta="Book Online Taxi Hamburg to Kiel | Taxi Services in Hamburg"
			description="Möchten Sie problemlos und bequem von Kiel und Hamburg aus reisen? Dann können Sie einen zuverlässigen Taxiservice in Kiel und Nemünster ausprobieren."
		/>
		<AboutUs language="DE" />
	</Layout>
);

export default AboutPage;
